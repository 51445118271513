import Vue from "vue";
import App from "./App.vue";
import Vant, { Toast } from "vant";
import "vant/lib/index.css";
import router from "@/router";

import "@/utils/dataHandling";

import { GetUrlParam } from '@/utils/dataHandling';

Vue.config.productionTip = false;

Vue.use(Vant);
Vue.prototype.$toast = Toast;

router.beforeEach((to,from,next)=>{
	const newObject = Object.assign(GetUrlParam(),{random_time: new Date().getTime()});
	if(to.hash!=""){
		next({
			path: to.path,
			query: newObject
		});
	}else{
		next();
	}
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
