<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { wxConfig } from "@/mixins/wxConfig";
export default {
  name: "App",
  // mixins:[wxConfig],
  data() {
    return {
      user_agent: "",
    };
  },
  created() {
    this.checkPlantForm();
  },
  mounted() {
    window.onpopstate = (e) => {
      let d = localStorage.getItem("allowBack");
      if (d == "false") {
        switch (e.target.location.pathname) {
          case "/tipsPage":
            this.closeApp();
            break;
          case "/paySuccess":
            this.closeApp();
            break;
          case "/receiveCoupon":
            this.closeApp();
            break;
          case "/parkingDetails":
            this.closeApp();
            break;
          default:
            history.go(1);
            break;
        }
      }
    };
  },
  methods: {
    checkPlantForm() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("micromessenger") > 0) {
        this.user_agent = "micromessenger";
        // this.receiveConfig();
      } else if (userAgent.indexOf("alipayclient") > 0) {
        this.user_agent = "alipayclient";
        AlipayJSBridge.call("hideOptionMenu");
      }
    },
    closeApp() {
      switch (this.user_agent) {
        case "alipayclient":
          AlipayJSBridge.call("exitApp");
          break;
        case "micromessenger":
          wx.closeWindow();
          break;
      }
    },
  },
};
</script>

<style>
#app {
  height: 100%;
}
</style>
