// 引入axios
import axios from "axios";
// 引入全局监听global.js

// 引入数据处理
import { reqTrim } from "./dataHandling";
// 创建一个新的axios请求
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 30000,
});

// 设置instance请求拦截器------------------------------------------------------------------
instance.interceptors.request.use(
  (config) => {
    // reqBody处理
    if (config.data) {
      if (reqTrim(config.data)) {
        // 字符串空白符处理
        config.data = {
          ...reqTrim(config.data),
        };
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);
// 添加响应拦截器------------------------------------------------------------------
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log("error", error);
    // 对响应错误做点什么
    return Promise.reject(error);
  },
);
export default instance;
