// Vue 引入
import Vue from "vue";
// 请求体处理---------------------------------------------------------
export const reqTrim = (reqBody) => {
  // 字符串空白符处理
  for (let key in reqBody) {
    // 思路 =》 判断string长度length>0 =》 删除string前后空白符 =》 再次计算string长度 =》 length>0 =》 true
    if (typeof reqBody[key] == "string" && reqBody[key].split("").length > 0) {
      // 键值类型 string  string长度
      let item = reqBody[key].trim(); // 删除空白符
      if (item.split("").length > 0) {
        // 再次计算strign长度
        reqBody[key] = item; // 重新赋值
      } else {
        Vue.prototype.$ele.notification("error", "输入框不能全部为空格！！");
        return false;
      }
    }
  }
  return reqBody;
};

// 车牌首位
export const cardFirstList = [
  "京",
  "沪",
  "浙",
  "苏",
  "粤",
  "鲁",
  "晋",
  "冀",
  "豫",
  "川",
  "渝",
  "辽",
  "吉",
  "黑",
  "皖",
  "鄂",
  "津",
  "贵",
  "云",
  "桂",
  "琼",
  "青",
  "新",
  "藏",
  "蒙",
  "宁",
  "甘",
  "陕",
  "闽",
  "赣",
  "湘",
  "港",
  "澳",
  "W",
];
// 车牌
export const wordArr = [
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "O",
  "P",
  "军",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "警",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
  "港",
  "澳",
];
export const numberList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

// export const callbackUrl = encodeURIComponent("http://fztc.natapp1.cc");
// export const callbackUrl = encodeURIComponent("http://192.168.1.3:8081");
/* 生产环境 */
export const callbackUrl = encodeURIComponent("https://park-pay.fzzhtc.com");

/* 测试环境 */
// export const callbackUrl = encodeURIComponent("https://park-pay2.fzzhtc.com");

export const wxAuthorize = (app_id, url, type) => {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${type}&connect_redirect=1#wechat_redirect`;
};

export const aliAuthorize = (app_id, url, type) => {
  window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${app_id}&auth_skip=IS_SKIP&scope=auth_base&state=${type}&redirect_uri=${url}`;
};

/**
 * 压缩base64
 * @param base64
 * @param multiple
 * @param quality
 * @returns {Promise<string>}
 */
export const compressImg = (base64, multiple, quality) => {
  if (!base64) {
    return false;
  }
  const length = base64.length / 1024;
  // 压缩方法
  let newImage = new Image();
  newImage.src = base64;
  newImage.setAttribute("crossOrigin", "Anonymous"); // url为外域时需要
  let imgWidth, imgHeight;
  let w;
  let promise = new Promise((resolve) => (newImage.onload = resolve));
  return promise.then(() => {
    w = newImage.width * multiple;
    imgWidth = newImage.width;
    imgHeight = newImage.height;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    if (Math.max(imgWidth, imgHeight) > w) {
      if (imgWidth > imgHeight) {
        canvas.width = w;
        // 等比例缩小
        canvas.height = w * (imgHeight / imgWidth);
      } else {
        canvas.height = w;
        // 等比例缩小
        canvas.width = w * (imgWidth / imgHeight);
      }
    } else {
      canvas.width = imgWidth;
      canvas.height = imgHeight;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height); //  // 这里面的 this 指向 newImage
    let smallBase64 = canvas.toDataURL("image/jpeg", quality); // 压缩语句
    console.log(`压缩前：${length}KB`);
    console.log(`压缩后：${smallBase64.length / 1024} KB`);
    return smallBase64;
  });
};

/**
 * 压缩base64
 * @param base64
 * @param multiple
 * @param quality
 * @returns {Promise<string>}
 */
export function compress(base64, multiple, quality) {
  let newImage = new Image();
  let imgWidth, imgHeight;
  let w;
  let promise = new Promise((resolve) => (newImage.onload = resolve));
  newImage.src = base64;
  return promise.then(() => {
    w = newImage.width * multiple;
    imgWidth = newImage.width;
    imgHeight = newImage.height;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    if (Math.max(imgWidth, imgHeight) > w) {
      if (imgWidth > imgHeight) {
        canvas.width = w;
        canvas.height = (w * imgHeight) / imgWidth;
      } else {
        canvas.height = w;
        canvas.width = (w * imgWidth) / imgHeight;
      }
    } else {
      canvas.width = imgWidth;
      canvas.height = imgHeight;
    }
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(newImage, 0, 0, canvas.width, canvas.height);
    let base64 = canvas.toDataURL("image/jpeg", quality);
    return base64;
  });
}

//
export const removeURLParameter = (url, parameter) => {
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var queryParams = urlparts[1].split("&");
    for (var i = 0; i < queryParams.length; i++) {
      var param = queryParams[i].split("=");
      if (param[0] == parameter) {
        queryParams.splice(i, 1);
      }
    }
    var newurl = urlparts[0] + "?" + queryParams.join("&");
    return encodeURIComponent(newurl);
  }
  return url;
};
// getUrlParams
export function GetUrlParam() {
  let url = document.location.toString();
  let arrObj = url.split("?");
  let params = Object.create(null);
  if (arrObj.length > 1) {
    arrObj = arrObj[1].split("&");
    arrObj.forEach((item) => {
      item = item.split("=");
      params[item[0]] = item[1];
    });
  }
  return params;
}
